// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";
@import "css/functions";

// 2. Include any default variable overrides here
$enable-gradients: true;
$enable-shadows: true;
$variable-prefix: "";

$black: #000;
$white: #fff;
$blue: #03a9f8;
$grey: #86939e;
$comet: #606676;
$steel-grey: #43484d;
$blue-bayoux: #5E6977;


$colors: (
    "black": $black,
    "white": $white,
    "grey": $grey,
    "comet": $comet,
    "steel-grey": $steel-grey,
    "blue-bayoux": $blue-bayoux,
    "blue": $blue,
);

$body-color: #86939e;
$body-bg: #fff;
$border-radius: .4rem;
$font-family-sans-serif: Roboto, sans-serif;
$font-weight-base: 300;


$font-size-base: .875rem; // 14px

$success: #7952b3;

$link-decoration: none;

$headings-font-weight: 300;
$headings-color: $comet;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    //xxl: 1320px
);

$h1-font-size:                2.5rem;
$h2-font-size:                2rem;
$h3-font-size:                1.75rem;
$h4-font-size:                1.5rem;
$h5-font-size:                1.25rem;
$h6-font-size:                1rem;

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/utilities";

// 4. Include any optional Bootstrap CSS as needed
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/pagination";

// 5. Add additional custom code here
@import "css/mixins/breakpoints";
@import "css/fonts";
@import "css/paper-icons";
@import "css/animations";
@import "css/nprogress";
@import "css/core-style";
@import "css/loader";
@import "css/index.scss";
@import "css/code";
@import "css/navbar";
@import "css/alerts";
@import "css/sources";
@import "css/tags";
@import "css/document";
@import "css/prism_override";
@import "css/error-page";

// 6. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

@import "css/utilities";
