/*
 * Core Styling
 * Should be at top of all other
 */

@include for-phablet-down {
    .responsive {
        text-align: center !important;
    }
    .responsive h1, .responsive h2, .responsive h3, .responsive p, .responsive a, .responsive .text-left, .responsive .text-right, .responsive img {
        text-align: center !important;
    }
}

@include for-phone-only {
    .responsive-phone {
        text-align: center;
    }
}

body {
    height: 100%;
    letter-spacing: 0.2px;
    padding-right: 0 !important;
}

a {
    color: var(--blue);
}

a:hover, a:focus, a:active {
    outline: none;
    text-decoration: none;
    color: var(--blue);
}

.h5 {
    font-size: font-size(14px);
    font-weight: $font-weight-normal;
    letter-spacing: 4px;
    text-transform: uppercase;
}

p {
    font-size: font-size(14px);
    font-weight: $font-weight-light;
    letter-spacing: 0.2px;
    //line-height: 30px;
}

img {
    max-width: 100%;
}

ul li {
    list-style: none;
    //font-weight: $font-weight-light;
}

ul li i {
    padding-right: 15px;
}

.unordered-list {
    &, & li {
        list-style-type: disc;
    }
    padding-left: 1rem;
    margin-bottom: 1.5rem;
}

header h2 {
    line-height: 60px;
}

.section {
    padding: 50px 0;
}

.section header {
    text-align: center;
    padding-bottom: 50px;
}

.section-heading {
    text-align: center;
    padding-bottom: 40px;
}

.section-title {
    font-weight: $font-weight-light;
    color: var(--steel-grey);
}

@include for-tablet-up {
    .section-title {
        font-size: font-size(52px);
    }
}

.page .search-section, .page .page-header {
    padding: 30px;
}

.page .search-section h1, .page .page-header h1 {
    font-size: 28px;
}

.page .search-section h1 small, .page .page-header h1 small {
    background: #03a9f4;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    padding: 3px 10px;
    vertical-align: middle;
}



.section-subtitle {
    font-size: font-size(22px);
    font-weight: 100;
    line-height: 32px;
    color: var(--blue-bayoux);
    width: 55%;
    margin: 20px auto 40px;
}

#page_wrap {
    height: 100%;
    overflow-x: hidden;
    position: relative;
    transition: all 0.5s ease 0s;
    width: 100%;
}

.offcanvas #page_wrap {
    overflow: hidden;
    position: absolute;
}

.offcanvas #page_wrap::after {
    background: rgba(245, 248, 250, 0.7) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 2s ease 0s;
    z-index: 101;
}

@include for-tablet-up {
    .enable-offcanvas .paper-nav-toggle,
    .enable-offcanvas #paper-offcanvas {
        display: block;
    }
}

.block {
    font-size: 14px;
}

.block img {
    border-radius: 3px;
    margin: 15px 0 20px;
    max-width: 100%;
}

.service-sidebar.sidebar-wrapper {
    background-color: #f9f9fb;
    border-radius: 5px;
    margin-bottom: 35px;
    padding: 20px;
}

.service-sidebar.sidebar-wrapper h2 {
    font-size: 24px;
    font-weight: normal;
    line-height: 36px;
    padding-bottom: 0;
    text-transform: capitalize;
}

.paper-block {
    padding: 15px;
    border: 3px solid #f5f8fa;
    border-radius: 3px;
    margin-bottom: 30px;
}

.lSAction > .lSNext {
    right: 30px;
}

.lSPager {
    margin-top: 30px !important;
}

ul {
    padding: 0;
}

ul.iconList li {
    line-height: 3;
    padding-left: 30px;
    position: relative;
}

ul.iconList li:before {
    color: #03a9f4;
    content: "";
    display: inline-block;
    font-family: paperIcons;
}

ul.arrowIcon li:before {
    content: "";
}

ul li:before {
    position: absolute;
    left: 0;
    top: 0;
}

.hero-header {
    position: relative;
    width: 100%;
    background-size: cover;
    background: rgba(28, 36, 65, 0.93);
    background-position: center;
    background-repeat: no-repeat;
}

.hero-header .table {
    display: table;
    height: 100%;
}

.hero-header .header-text {
    display: table-cell;
    vertical-align: middle;
}

.hero-header .container {
    height: 100%;
}

.cut-top {
    content: ' ';
    position: absolute;
    top: -80px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 80px solid transparent;
    border-right: 30px solid white;
}

.cut-bottom {
    content: ' ';
    position: absolute;
    bottom: -80px;
    left: 0;
    width: 0;
    height: 0;
    border-bottom: 80px solid transparent;
    border-left: 30px solid white;
}

.cut-top-left {
    z-index: +2;
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    top: 0;
    right: 0;
    border-width: 0 900px 80px 0;
    border-color: transparent #fff transparent transparent;
}

.cut-top-right {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    top: 0;
    left: 0;
    border-width: 80px 450px 0 0;
    border-color: #fff transparent transparent transparent;
    z-index: 1;
}

.cut-bottom-left {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    bottom: 0;
    right: 0;
    border-width: 0 0 50px 893px;
    border-color: transparent transparent #fff transparent;
    z-index: 1;
}

.cut-bottom-right {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    bottom: 0;
    left: 0;
    border-width: 50px 0 0 650px;
    border-color: transparent transparent transparent #fff;
    z-index: 1;
}

.portfolio-masonry .masonry-post {
    padding: 0 10px;
}

@media (min-width: 1400px) {
    .inside {
        margin: 0 auto;
        background: #fff;
        width: 80%;
    }
}

.section-heading-icon {
    padding-top: 84px;
    background: transparent url("./images/icon/icon-stone1.png") no-repeat scroll center bottom;
}

.masthead {
    display: table-cell;
    vertical-align: middle;
    width: 1%;
    padding: 170px 0 120px;
    text-align: center;
}

.card-header {
    position: relative;
}

.card-header .card-handle {
    position: absolute;
    right: 20px;
}

.content-wrapper {
    padding: 40px 0;
}

.section {
    padding: 50px 0;
}

.section header {
    text-align: center;
    padding-bottom: 50px;
}

.section header h1 {
    text-transform: uppercase;
}


/***************************** Header ****************************/
.circles-header {
    background: url("./images/icon/icon-circles.png") no-repeat scroll center bottom;
}

.home-search {
    background: url("./images/icon/icon-circles.png") no-repeat scroll center bottom;
    margin-bottom: 100px;
}

.home-search::after {
    background: transparent url("./images/icon/icon-telescope.png") no-repeat scroll center bottom;
    bottom: -65px;
    content: "";
    display: block;
    height: 128px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    width: 128px;
}

.search-section {
    background-color: #F5F8FA;
    position: relative;
    border-bottom: 1px solid #e1e8ee;
    border-top: 1px solid #e1e8ee;
}

.masthead {
    padding: 100px 0 80px 0;
    text-align: center;
}

.masthead h1 {
    color: #86939e;
    font-weight: 900;
    font-size: 60px;
}

@media (max-width: 1031px) {
    .masthead h1 {
        font-size: 30px;
    }
}

.masthead p {
    color: #86939e;
    font-weight: 100;
    margin: 0 auto;
    padding-bottom: 15px;
    padding-top: 30px;
    text-align: center;
    width: 70%;
}

@media (min-width: 1031px) {
    .masthead p {
        font-size: 22px;
    }
}

.masthead form {
    position: relative;
    margin-top: 10px;
}

.masthead form button {
    position: absolute;
    right: 25px;
    top: 25px;
    background: none;
    border: none;
    font-size: 30px;
    color: #86939e;
}

@media (max-width: 1031px) {
    .masthead form button {
        right: 5px;
    }
}

.masthead .btn-hero {
    border: 3px solid #fff;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 50px;
}

.masthead .btn-hero:hover {
    background: rgba(255, 255, 255, 0.2);
    -webkit-transition: background-color 500ms ease-out 0.2s;
    -moz-transition: background-color 500ms ease-out 0.2s;
    -o-transition: background-color 500ms ease-out 0.2s;
    transition: background-color 500ms ease-out 0.2s;
}

.section {
    margin-top: 80px;
    margin-bottom: 80px;
}

.section header {
    text-align: center;
    padding: 40px;
}

.template-genre .overlay {
    background: rgba(2, 28, 53, 0.9) none repeat scroll 0 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.search-field {
    width: 100%;
    font-size: 18px;
    border-radius: 30px;
    border: 2px solid #e1e8ee;
    padding: 0px 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 60px;
    -webkit-appearance: none;
}

#app {
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

.loaded {
    overflow: visible;
}

.loader-fade {
    display: none;
}

/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #03a9f4;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
}

#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #03a9f4, 0 0 5px #03a9f4;
    opacity: 1.0;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 9999;
    top: 15px;
    right: 15px;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: #03a9f4;
    border-left-color: #03a9f4;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

/* Fancy blur effect */
/* Remove these to get rid of the spinner */
.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.input-group-addon {
    min-height: 3rem;
    border-color: #e1e8ee;
    background: #F5F8FA;
    padding: 0.7rem 1rem;
}

.form-control {
    border: 1px solid #e1e8ee;
    box-shadow: none;
    border-radius: 3px;
    transition: all .2s ease-in-out;
    font-weight: 300;
}

.form-control:focus {
    box-shadow: none;
    border-color: #1BB1F5;
}

.form-group {
    position: relative;
}

.form-group.has-icon i {
    font-size: 14px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 20px;
}

.form-group.has-icon i:after {
    content: "";
    height: 30px;
    width: 1px;
    border-right: solid 1px #e1e8ee;
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.form-group.has-icon input {
    padding-left: 68px;
}

.has-warning .form-control {
    border-color: orange;
}

form label {
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon {
    border: 2px solid #e1e8ee;
    border-radius: 5px;
    color: #43484d;
    font-size: 14px;
    height: 50px;
    padding: 15px;
    width: 100%;
}

.form-control-lg:focus, .input-group-lg > .form-control:focus,
.input-group-lg > .input-group-prepend > .input-group-text:focus,
.input-group-lg > .input-group-append > .input-group-text:focus,
.input-group-lg > .input-group-prepend > .btn:focus,
.input-group-lg > .input-group-append > .btn:focus,
.input-group-lg > .form-control:focus,
.input-group-lg > .input-group-addon:focus {
    border-color: #03a9f4 !important;
}

textarea.form-control-lg, .input-group-lg > textarea.form-control,
.input-group-lg > .input-group-prepend > textarea.input-group-text,
.input-group-lg > .input-group-append > textarea.input-group-text,
.input-group-lg > .input-group-prepend > textarea.btn,
.input-group-lg > .input-group-append > textarea.btn {
    min-height: 100px;
    padding: 0 .85rem;
}

input[type="checkbox"], input[type="radio"] {
    margin-right: 10px !important;
}

.btn {
    border-radius: 2px;
    font-weight: 300;
}

.btn i {
    padding-right: 10px;
}

.btn .icon {
    padding-right: 15px;
}

.btn:hover {
    border: 1px solid transparent;
}

.btn-default {
    border: 2px solid #e1e8ee;
    background: #f5f8fa;
    color: #03a9f4 !important;
}

.btn-default:hover {
    border: 2px solid #03a9f4;
}

.btn-fixed-top-left {
    position: fixed;
    left: 52px;
    top: 66px;
    z-index: 99;
}

.btn-primary {
    border-color: transparent;
    color: #fff;
}

.btn-primary:hover, .btn-primary:focus {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
    opacity: 0.8;
    color: #fff;
    background: #03a9f4;
}

.btn-danger {
    border-color: transparent;
    color: #fff;
}

.btn-danger:hover, .btn-danger:focus {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
    opacity: 0.8;
    color: #fff;
    background: #ED5564;
}

.btn-warning {
    border-color: transparent;
    color: #fff;
}

.btn-warning:hover, .btn-warning:focus {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
    opacity: 0.8;
    color: #fff;
    background: #FCCE54;
}

.btn-success {
    border-color: transparent;
    color: #fff;
}

.btn-success:hover, .btn-success:focus {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
    opacity: 0.8;
    color: #fff;
    background: #7dc855;
}

.badge-success, .badge-warning {
    color: #fff;
}

.btn-social {
    color: #fff;
}

.btn-social:hover, .btn-social:focus {
    color: #fff;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
}

.btn-big {
    padding: 15px 40px !important;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400 !important;
}

.btn-big:hover {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
}

@media (max-width: 1031px) {
    .btn-big {
        font-size: 14px;
    }
}

.btn-img img:hover {
    transition: box-shadow 500ms ease-out 0.05s;
    background: transparent;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
}

.btn-line {
    display: inline-block;
    color: #fff;
    padding: 10px 22px;
    line-height: 1;
    border: 1px solid #fff;
    border-radius: 0;
    text-align: center;
    font-weight: 100;
    margin: 4px;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    background: transparent;
}

.btn-line:hover, .btn-line:focus {
    transition: box-shadow 500ms ease-out 0.05s;
    opacity: 0.8;
    color: #fff;
    border: 1px solid;
}

.btn-lg, .btn-group-lg > .btn {
    padding: 13px 25px;
    border-radius: 3px;
    font-size: 16px;
}

.btn-fab {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
}

.btn-fab:hover {
    text-decoration: none;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 0.8;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
}

.btn-fab i {
    font-size: 24px;
    vertical-align: middle;
}

.btn-fab.btn-fab-md {
    width: 50px;
    height: 50px;
    line-height: 50px;
}

.paper-nav-toggle {
    width: 25px;
    height: 25px;
    cursor: pointer;
    text-decoration: none;
}

.paper-nav-toggle.active i::before, .paper-nav-toggle.active i::after, .paper-nav-toggle.dl-active i::before, .paper-nav-toggle.dl-active i::after {
    background: #03a9f4;
}

.paper-nav-toggle:hover, .paper-nav-toggle:focus, .paper-nav-toggle:active {
    outline: none;
    border-bottom: none !important;
    opacity: 0.8;
}

.paper-nav-toggle i {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 2px;
    color: #03a9f4;
    font: bold 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: #03a9f4;
    transition: all .2s ease-out;
}

.paper-nav-toggle i::before, .paper-nav-toggle i::after {
    content: '';
    width: 25px;
    height: 2px;
    background: #03a9f4;
    position: absolute;
    left: 0;
    transition: all .2s ease-out;
}

.paper-nav-toggle.paper-nav-white > i {
    color: #fff;
    background: #fff;
}

.paper-nav-toggle.paper-nav-white > i::before, .paper-nav-toggle.paper-nav-white > i::after {
    background: #fff;
}

.paper-nav-toggle.left {
    left: 0;
    right: auto;
}

.paper-nav-toggle i::before {
    top: -7px;
}

.paper-nav-toggle i::after {
    bottom: -7px;
}

.paper-nav-toggle:hover i::before {
    top: -10px;
}

.paper-nav-toggle:hover i::after {
    bottom: -10px;
}

.paper-nav-toggle.active i, .paper-nav-toggle.dl-active i {
    background: transparent;
}

.paper-nav-toggle.active i::before, .paper-nav-toggle.dl-active i::before {
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.paper-nav-toggle.active i::after, .paper-nav-toggle.dl-active i::after {
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
}

.paper-nav-toggle {
    position: absolute;
    right: 0;
    top: 10px;
    padding: 6px 0 0 0;
    display: block;
    margin: 0 auto;
    display: block;
    height: 44px;
    width: 44px;
    z-index: 2001;
    border-bottom: none !important;
    text-align: center;
}

@media (max-width: 1031px) {
    .paper-nav-toggle {
        position: absolute;
        display: block;
    }
}

.paper-nav-toggle .left {
    left: 15px;
}

.glow {
    color: #444;
    text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc, 1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc, 3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc, 5px 6px 1px #eee, 7px 6px 1px #ccc;
}

.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.social {
    list-style: none;
    padding: 35px 0;
}

.social li {
    display: inline-block;
    border-radius: 50%;
    color: #fff;
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 18px;
    margin-right: 15px;
    text-align: center;
}

.social li a {
    color: #fff;
}

.social li i {
    padding: 0;
}

.social.square li {
    border-radius: 0;
}

.social.rounded li {
    border-radius: 10px;
}

.facebook {
    background: #507cbe;
}

.twitter {
    background: #63cdf1;
}

.gplus {
    background: #f16261;
}

.instagram {
    background: #444444;
}

.youtube {
    background: #CA3737;
}

.linkedin {
    background: #90cadd;
}

.dribbble {
    background: #d97aa6;
}

.behance {
    background: #0785f3;
}

.offcanvas-page {
    margin-left: 270px;
    overflow: hidden;
    transition: transform 0.5s ease-in-out, margin 0.5s ease-in-out;
}

@media (max-width: 1031px) {
    .offcanvas-page {
        margin-left: 0;
    }
}

.user_avatar {
    width: 60px;
    border: 1px solid #eee;
    background: #fff;
    padding: 5px;
    border-radius: 50%;
}

.user-panel {
    padding: 40px 20px;
}

.user-panel .user_avatar {
    margin-right: 10px;
}

.sidebar-collapse .offcanvas-page {
    margin-left: 0;
}

.offcanvas {
    transition: transform 0.5s ease-in-out, margin 0.5s ease-in-out;
    overflow: hidden;
}

.offcanvas .dl-menuwrapper {
    top: 0;
    right: auto;
}

.offcanvas .dl-menuwrapper .dl-menu {
    box-shadow: none;
    border-radius: 0;
}

.offcanvas .sidebar-menu li a {
    padding-top: 12px;
    padding-bottom: 12px;
}

.offcanvas .sidebar-menu > li {
    border-bottom: 1px solid #f8f8f8;
}

.offcanvas .sidebar-menu > li.active:after {
    content: "";
    display: block;
    width: 5px;
    height: 100%;
    background: #03A9F4;
    position: absolute;
    right: 0;
    bottom: 0;
}

.offcanvas .sidebar-menu > li > .treeview-menu {
    background-color: #fafafa;
}

.offcanvas .sidebar-menu > li > .treeview-menu > li {
    border-bottom: 1px solid #f8f8f8;
    padding: 3px 0;
}

.offcanvas .sidebar-menu > li > .treeview-menu > li .treeview-menu {
    background-color: #fff;
}

.sidebar-toggle {
    float: left;
    background-color: transparent;
    background-image: none;
    padding: 15px 15px;
    font-family: paperIcons;
    position: absolute;
}

.sidebar-toggle:before {
    content: "";
    font-size: 22px;
    color: #55ACEE;
}

.logo-offcanvas {
    padding: 70px 0;
    text-align: center;
}

.sidebar-menu {
    list-style: outside none none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.sidebar-menu > li {
    margin: 0;
    padding: 0;
    position: relative;
}

.sidebar-menu > li > a {
    position: relative;
    display: block;
    padding: 0 5px 10px 30px;
    font-size: 14px;
    color: #86939e;
}

.sidebar-menu > li > a > .fa,
.sidebar-menu > li > a > .icon,
.sidebar-menu > li > a > .glyphicon,
.sidebar-menu > li > a > .ion {
    width: 20px;
    margin-right: 10px;
    position: absolute;
    top: 3px;
    left: 0;
    z-index: 1;
}

.sidebar-menu > li .label {
    margin-right: 5px;
}

.sidebar-menu > li .badge {
    margin-right: 5px;
    margin-top: 3px;
}

.sidebar-menu li.header {
    font-size: 12px;
    padding: 10px 25px 10px 15px;
}

.sidebar-menu li > a > .icon-angle-left,
.sidebar-menu li > a > .pull-right-container > .icon-angle-left {
    height: auto;
    margin-right: 10px;
    padding: 0;
    width: auto;
}

.sidebar-menu li > a > .icon-angle-left {
    margin-top: -8px;
    position: absolute;
    right: 10px;
    top: 50%;
}

.sidebar-menu li.active > a > .icon-angle-left,
.sidebar-menu li.active > a > .pull-right-container > .icon-angle-left {
    transform: rotate(-90deg);
}

.sidebar-menu li.active > .treeview-menu {
    display: block;
}

.sidebar-menu .treeview-menu {
    display: none;
    list-style: outside none none;
    margin: 0;
    padding: 0 0 0 5px;
}

.sidebar-menu .treeview-menu .treeview-menu {
    padding-left: 20px;
}

.sidebar-menu .treeview-menu > li {
    margin: 0;
}

.sidebar-menu .treeview-menu > li > a {
    display: block;
    font-size: 12px;
    font-weight: 300;
    padding: 5px 5px 5px 15px;
}

.sidebar-menu .treeview-menu > li > a > .fa,
.sidebar-menu .treeview-menu > li > a > .icon,
.sidebar-menu .treeview-menu > li > a > .glyphicon,
.sidebar-menu .treeview-menu > li > a > .ion {
    width: 20px;
    margin-right: 10px;
}

.sidebar-menu .treeview-menu > li > a > .pull-right-container > .icon-angle-left,
.sidebar-menu .treeview-menu > li > a > .pull-right-container > .icon-angle-down {
    width: auto;
}

.sidebar-menu .treeview-menu > li > a > .icon-angle-left,
.sidebar-menu .treeview-menu > li > a > .icon-angle-down {
    width: auto;
}

@media (min-width: 768px) {
    .sidebar-mini.sidebar-collapse .offcanvas-page,
    .sidebar-mini.sidebar-collapse .right-side,
    .sidebar-mini.sidebar-collapse .main-footer {
        margin-left: 70px !important;
        z-index: 840;
    }
    .sidebar-mini.sidebar-collapse .main-sidebar {
        transform: translate(0px, 0px);
        width: 70px !important;
        z-index: 850;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li {
        position: relative;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a {
        font-size: 26px;
        padding-left: 22px;
        margin-right: 0;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {
        border-top-right-radius: 4px;
        display: none;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span {
        border-bottom-right-radius: 4px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
        border-bottom-right-radius: 4px;
        padding-bottom: 5px;
        padding-top: 5px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right),
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
        display: block !important;
        left: 50px;
        position: absolute;
        width: 180px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span {
        background-color: inherit;
        margin-left: -3px;
        padding: 12px 5px 12px 20px;
        top: 0;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container {
        float: right;
        left: 180px !important;
        position: relative !important;
        top: -22px !important;
        width: auto !important;
        z-index: 900;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container > .label:not(:first-of-type) {
        display: none;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
        margin-left: 0;
        top: 44px;
    }
    .sidebar-mini.sidebar-collapse .main-sidebar .user-panel > .info,
    .sidebar-mini.sidebar-collapse .sidebar-form {
        display: none !important;
        transform: translateZ(0px);
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu,
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right {
        display: none !important;
        transform: translateZ(0px);
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
        display: none !important;
        transform: translateZ(0px);
    }
    .sidebar-mini.sidebar-collapse .main-header .logo {
        width: 50px;
    }
    .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
        display: block;
        font-size: 18px;
        margin-left: -15px;
        margin-right: -15px;
    }
    .sidebar-mini.sidebar-collapse .main-header .logo > .logo-lg {
        display: none;
    }
    .sidebar-mini.sidebar-collapse .main-header .navbar {
        margin-left: 50px;
    }
}

.main-sidebar .user-panel {
    overflow: hidden;
    white-space: nowrap;
}

.sidebar-menu > li.header {
    overflow: hidden;
    white-space: nowrap;
}

.sidebar-menu:hover {
    overflow: visible;
}

.sidebar-form {
    overflow: hidden;
    text-overflow: clip;
}

.sidebar-menu > li.header {
    overflow: hidden;
    text-overflow: clip;
}

.sidebar-menu li > a {
    position: relative;
}

.sidebar-menu li > a > .pull-right {
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
}

.main-sidebar,
.left-side {
    top: 0;
    left: 0;
    min-height: 100%;
    position: absolute;
    width: 270px;
    z-index: 810;
    background: #fff;
    -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
    -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
    -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

@media (min-width: 1031px) {
    .relative-lg {
        position: relative;
    }
}

@media (max-width: 1025px) {
    .main-sidebar,
    .left-side {
        -webkit-transform: translate(-330px, 0);
        -ms-transform: translate(-330px, 0);
        -o-transform: translate(-330px, 0);
        transform: translate(-330px, 0);
    }
}

@media (min-width: 1025px) {
    .sidebar-collapse .main-sidebar,
    .sidebar-collapse .left-side {
        -webkit-transform: translate(-330px, 0);
        -ms-transform: translate(-330px, 0);
        -o-transform: translate(-330px, 0);
        transform: translate(-330px, 0);
    }
}

@media (max-width: 1024px) {
    .sidebar-open .main-sidebar,
    .sidebar-open .left-side {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

pre {
    padding: 20px;
    font-size: 13px;
    color: #fff;
    background-color: #082c46;
    border-radius: 3px;
}

code {
    font-size: 85%;
    line-height: 1.25;
    padding: .1rem .2rem;
    color: #d73e48;
    border-radius: .1rem;
    background: #fcf2f2;
}

.modal-footer {
    background: #F5F8FA;
}

.progress.progress-xs {
    height: 0.25rem;
}

.nav-material {
    border-bottom: 2px solid #e1e8ee;
}

.nav-material > li {
    position: relative;
}

.nav-material > li .nav-link.active {
    border: 0;
    background: transparent;
    border-bottom: 2px solid #03a9f4;
}

.nav-material > li > .nav-link {
    border: none;
}

.nav-material > li > a:hover {
    border: none;
    color: #03a9f4 !important;
    background: transparent;
}

.nav-material > li > a::after {
    content: "";
    background: #03a9f4;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    transition: all 250ms ease 0s;
    transform: scale(0);
}

.nav-material > li:hover > a::after {
    transform: scale(1);
}

.nav-material.nav-material-white {
    border: none;
}

.nav-material.nav-material-white > li .nav-link.active {
    border-color: #fff;
}

.nav-material.nav-material-white > li .nav-link > a {
    color: #fff !important;
}

.nav-material.nav-material-white > li .nav-link > a:hover {
    color: #fff !important;
}

.nav-material.nav-material-white > li .nav-link > a::after {
    background: #fff !important;
}

.nav-material > li > a::after {
    background: #03a9f4 none repeat scroll 0% 0%;
    color: #fff;
}

.v_tabs .tabs-left,
.v_tabs .tabs-right {
    border-bottom: none;
    padding-top: 2px;
}

.v_tabs .tabs-left {
    border-right: 3px solid #f5f8fa;
    padding-right: 70px;
}

.v_tabs .tabs-right {
    border-left: 3px solid #f5f8fa;
}

.v_tabs .tabs-left > li,
.v_tabs .tabs-right > li {
    float: none;
    margin-bottom: 2px;
}

.v_tabs .tabs-left > li {
    margin-right: -1px;
}

.v_tabs .tabs-right > li {
    margin-left: -1px;
}

.v_tabs .tabs-right > li > a {
    border-radius: 0 4px 4px 0;
    margin-right: 0;
}

.v_tabs .tab-pane li {
    padding-bottom: 20px;
    color: #86939e;
}

.v_tabs .tabs-left li .nav-link {
    position: relative;
    border-radius: 4px 0 0 4px;
    margin-right: 0;
    display: block;
    color: #86939e;
    border: none;
}

.v_tabs .tabs-left li .nav-link:hover {
    background-color: #03A9F4;
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
}

.v_tabs .tabs-left li .nav-link:focus {
    border: 3px solid #f5f8fa;
    border-radius: 5px;
}

.v_tabs .tabs-left li .nav-link.active {
    border: 3px solid #f5f8fa;
    border-radius: 5px;
}

.v_tabs .tabs-left li .nav-link.active::after {
    border: 2px solid #f5f8fa;
    color: wheat;
    content: "";
    height: 3px;
    position: absolute;
    right: -74px;
    top: 50%;
    width: 73px;
}

@media (max-width: 1031px) {
    .v_tabs .tabs-left li .nav-link.active::after {
        display: none;
    }
}

@media (max-width: 980px) {
    .tabs-left li.active::after {
        left: 208px;
    }
}

@media (max-width: 768px) {
    #lightSlider li img {
        width: 73%;
        margin-right: 20px;
    }
    .mokupSlider li a {
        height: 360px;
    }
}

@media (max-width: 420px) {
    #lightSlider li img {
        width: 75%;
        margin: 0;
    }
    .mokupSlider li a {
        height: 130px;
    }
    .tabs-left {
        padding-right: 10px;
    }
    .tabs-left li.active::after {
        border: none;
    }
}

.thumbnail {
    padding: 15px;
    border: 3px solid #f5f8fa;
}

.thumbnail iframe {
    height: 180px;
    width: 100%;
}

.badge {
    border-radius: 0;
    padding: 6px 10px;
    font-weight: 300;
}

.badge.badge-mini {
    padding: 0;
    font-size: 10px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    vertical-align: middle;
    position: absolute;
    left: 18px;
    top: 2px;
}

.panel {
    border-radius: 1px;
}

.panel-heading {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 10px 15px;
}

.list-group-item {
    border: none;
}

.btn-secondary {
    color: #86939e;
}

.well {
    padding: 40px;
    background-color: #F5F8FA;
    border: 1px solid #F5F8FA;
}

.pagination li a {
    border-color: #e1e8ee;
}

.list-group-item {
    border: 1px solid #e1e8ee;
}

.thumbnail {
    margin-bottom: 15px;
}

.thumbnail .caption {
    padding: 25px 4px 10px;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-t-10 {
    padding-top: 10px !important;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.p-t-40 {
    padding-top: 40px !important;
}

.p-b-40 {
    padding-bottom: 40px !important;
}

.p-t-80 {
    padding-top: 80px !important;
}

.p-b-80 {
    padding-bottom: 80px !important;
}

.p-b-100 {
    padding-bottom: 100px !important;
}

.p-t-100 {
    padding-top: 100px !important;
}

.p-t-b-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.p-t-b-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.p-t-b-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.p-t-b-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.p-t-b-80 {
    padding: 80px 0 !important;
}

.p-t-b-100 {
    padding: 100px 0 !important;
}

.p-10 {
    padding: 10px !important;
}

.p-40 {
    padding: 40px !important;
}

.p-t-50 {
    padding-top: 50px !important;
}

.m-t-50 {
    margin-top: 50px !important;
}

.m-t-150 {
    margin-top: 150px;
}

@media (max-width: 1031px) {
    .m-t-150 {
        margin-top: 50px;
    }
}

.m-b-50 {
    margin-bottom: 50px !important;
}

.m-t-b-50 {
    margin: 50px 0 !important;
}

.p-b-0 {
    padding-bottom: 0 !important;
}

.b-b {
    border-bottom: 1px solid #e1e8ee !important;
}

@media (min-width: 1031px) {
    .b-r {
        border-right: 1px solid #e1e8ee;
    }
    .b-l {
        border-left: 1px solid #e1e8ee;
    }
    .b-r-o {
        border-right: 1px solid rgba(0, 0, 0, 0.09);
    }
    .b-l-o {
        border-left: 1px solid rgba(0, 0, 0, 0.09);
    }
}

.b-t {
    border-top: 1px solid #e1e8ee;
}

.b-t-b {
    border-top: 1px solid #e1e8ee;
    border-bottom: 1px solid #e1e8ee;
}

.border {
    border: 1px solid #e1e8ee;
}

.section-text-white h1, .section-text-white h2, .section-text-white h3, .section-text-white h4, .section-text-white h5, .section-text-white h6, .section-text-white p, .section-text-white span {
    color: #fff !important;
}

.no-p {
    padding: 0 !important;
}

.no-m {
    margin: 0 !important;
}

.no-b, .b-0, .no-b .mainnav, .no-b li {
    border: medium none !important;
}

.no-r, .r-0 {
    border-radius: 0 !important;
}

.separator {
    border-bottom: 1px solid #edf0f2;
}

.grid img {
    border-radius: 0;
}

.r-3 {
    border-radius: 3px !important;
}

.r-5 {
    border-radius: 5px !important;
}

.r-10 {
    border-radius: 10px !important;
}

.r-15 {
    border-radius: 15px !important;
}

.r-20 {
    border-radius: 20px !important;
}

.r-30 {
    border-radius: 30px !important;
}

.paper-card {
    background: #fff;
    padding: 40px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.shadow {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.shadow1 {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
}

.shadow2 {
    box-shadow: 0 0 25px 0 #e5f0fa;
}

.no-shadow {
    box-shadow: none !important;
}

.text-big-bold {
    font-size: 62px;
    font-weight: 800;
    line-height: 62px;
}

.font-weight-lighter, .thin {
    font-weight: 100 !important;
}

.font-weight-bolder, .bolder {
    font-weight: 900 !important;
}

.l-s-1 {
    letter-spacing: .2rem;
}

.l-s-2 {
    letter-spacing: .4rem;
}

.l-s-3 {
    letter-spacing: .6rem;
}

.l-s-4 {
    letter-spacing: .8rem;
}

.l-s-5 {
    letter-spacing: 1rem;
}

.s-12 {
    font-size: 12px !important;
}

.s-14 {
    font-size: 14px !important;
}

.s-18 {
    font-size: 18px !important;
}

.s-24 {
    font-size: 24px !important;
}

.s-36 {
    font-size: 36px !important;
}

@media (min-width: 1031px) {
    .s-48 {
        font-size: 48px !important;
    }
}

@media (min-width: 1031px) {
    .s-64 {
        font-size: 64px !important;
    }
}

@media (min-width: 1031px) {
    .s-128 {
        font-size: 128px !important;
    }
}

.s-256 {
    font-size: 256px !important;
}

@media (max-width: 420px) {
    .s-256 {
        font-size: 128px !important;
    }
}

.img-40 {
    width: 40px;
    height: 40px;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.img-80 {
    width: 80px;
}

.img-100 {
    width: 100px;
}

.img-150 {
    width: 150px;
}

.height-50 {
    height: 50px;
}

.height-90 {
    height: 90px;
}

.height-100 {
    height: 100px;
}

.height-full {
    background: #F3F5F8;
    min-height: 100vh;
}

.width-400 {
    max-width: 400px;
}

.width-250 {
    width: 250px !important;
}

.width-900 {
    max-width: 900px;
}

.img-border {
    padding: 5px;
    border: 1px solid #e1e8ee;
}

.text-white {
    color: #fff !important;
}

.text-white p,
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white strong,
.text-white a,
.text-white b,
.text-white li,
.text-white i {
    color: #fff !important;
}

.text-blue {
    color: #4ea9e0 !important;
}

.text-red {
    color: #e84c3d !important;
}

.text-green {
    color: #40d17d !important;
}

.text-purple {
    color: #9b58b5 !important;
}

.text-black {
    color: #000 !important;
}

.text-yellow {
    color: #f1c40f !important;
}

.white {
    background: #fff !important;
}

.light {
    background-color: #F5F8FA;
}

.light-2 {
    background: #F6FAFD;
}

.text-light-blue {
    color: #03a9f4 !important;
}

.light-blue {
    background-color: #03a9f4 !important;
}

.dark-blue {
    background-color: #26374A !important;
}

.blue4 {
    background-color: #274da8 !important;
}

.blue3 {
    background-color: #375EBC !important;
}

.blue2 {
    background-color: #4CA5E8 !important;
}

.blue1 {
    background-color: #78BCEE !important;
}

.success {
    background-color: #68ba3c !important;
}

.strawberry, .danger {
    background-color: #ED5564 !important;
}

.sunfollower, warning {
    background-color: #FCCE54 !important;
}

.grey-black {
    background-color: #454545 !important;
}

.dark-grey {
    background-color: #2B2D34 !important;
}

.paper-white {
    background: #f5f8fa;
}

.mint {
    background-color: #45CFC0 !important;
}

.gradient {
    border: none;
    background: #4c88ef;
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #4c88ef), color-stop(100%, #17e4d9));
    background: -webkit-linear-gradient(-45deg, #4c88ef 0%, #17e4d9 100%);
    background: -webkit-linear-gradient(315deg, #4c88ef 0%, #17e4d9 100%);
    background: linear-gradient(135deg, #4c88ef 0%, #17e4d9 100%);
}

.gradient-green {
    background-image: linear-gradient(45deg, #00E3AE 0%, #9BE15D 100%);
}

.center {
    margin: 0 auto;
}

.border-list li {
    border-bottom: 1px solid #eee;
}

.border-list li:last-child {
    border: none;
}

@media (min-width: 1031px) {
    .big-heading {
        font-size: 72px;
    }
}

.parallel {
    overflow-x: hidden;
    width: 100%;
}

@media (min-width: 1031px) {
    .parallel {
        overflow: hidden;
        position: fixed;
    }
}

.overlay-light::before {
    content: '';
    background: rgba(255, 255, 255, 0.78);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.overlay-light::before {
    content: '';
    background: rgba(255, 255, 255, 0.78);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.overlay-light::before {
    content: '';
    background: rgba(255, 255, 255, 0.78);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.overlay-dark::before {
    content: '';
    background: rgba(0, 0, 0, 0.78);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.section {
    position: relative;
}

.relative {
    position: relative !important;
}

.fixed {
    position: fixed !important;
}

[data-bg-repeat='false'] {
    background-repeat: no-repeat !important;
}

[data-bg-possition='bottom'] {
    background-position: bottom !important;
}

[data-bg-possition='top'] {
    background-position: top !important;
}

[data-bg-possition='center'] {
    background-position: center !important;
}

[data-bg-possition='centerleft'] {
    background-position: center left !important;
}

.grid [class*='col-'], .grid figure {
    padding: 0;
    margin: 0;
}

@media (min-width: 1031px) {
    .grid.grid-border [class*='col-'] {
        border-right: 1px solid #eee;
    }
    .grid.grid-border [class*='col-']:last-child {
        border-right: 0;
    }
    .grid.grid-border.row {
        border-top: 1px solid #eee;
    }
    .grid.grid-border.row:nth-of-type(1) {
        border-top: none !important;
    }
}

ul.list-group.list-group-striped li:nth-of-type(even) {
    background: #F5F8FA;
}

ul.list-group.list-group-hover li:hover {
    background: red;
}

/*
 * Basic or Must have Elements
 * You might not want to comment or delete them
 */
/* Common styles of menus */
.dl-menuwrapper {
    width: 100%;
    max-width: 300px;
    float: left;
    position: relative;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    -webkit-perspective-origin: 50% 200%;
    perspective-origin: 50% 200%;
}

.dl-menuwrapper:first-child {
    margin-top: 25px;
}

.dl-menuwrapper button {
    background: #ccc;
    border: none;
    width: 48px;
    height: 45px;
    text-indent: -900em;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    outline: none;
}

.dl-menuwrapper button:hover,
.dl-menuwrapper button.dl-active,
.dl-menuwrapper ul {
    background: #aaa;
}

.dl-menuwrapper button:after {
    content: '';
    position: absolute;
    width: 68%;
    height: 5px;
    background: #fff;
    top: 10px;
    left: 16%;
    box-shadow: 0 10px 0 #fff, 0 20px 0 #fff;
}

.dl-menuwrapper ul {
    padding: 0;
    list-style: none;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.dl-menuwrapper li {
    position: relative;
}

.dl-menuwrapper li a {
    display: block;
    position: relative;
    padding: 15px 20px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 300;
    color: #fff;
    outline: none;
}

.no-touch .dl-menuwrapper li a:hover {
    background: rgba(255, 248, 213, 0.1);
}

.dl-menuwrapper li.dl-back > a {
    padding-left: 30px;
    background: #f5f8fa;
}

.dl-menuwrapper li.dl-back:after,
.dl-menuwrapper li > a:not(:only-child):after {
    position: absolute;
    top: 0;
    line-height: 50px;
    font-family: 'paperIcons';
    speak: none;
    -webkit-font-smoothing: antialiased;
    content: "";
    font-size: 18px;
    right: 22px;
}

.dl-menuwrapper li.dl-back:after {
    left: 10px;
    color: rgba(212, 204, 198, 0.6);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.dl-menuwrapper li > a:after {
    right: 10px;
    color: rgba(0, 0, 0, 0.15);
}

.dl-menuwrapper .dl-menu {
    margin: 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
    transition: all 0.3s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}

/* Hide the inner submenus */
.dl-menuwrapper li .dl-submenu {
    display: none;
}

/*
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menuwrapper .dl-menu.dl-subview li,
.dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menuwrapper .dl-menu.dl-subview li.dl-subview > a {
    display: none;
}

.dl-menuwrapper .dl-menu.dl-subview li.dl-subview,
.dl-menuwrapper .dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen,
.dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
    display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
}

/* Animation classes for moving out and in */
.dl-menuwrapper .dl-menu.dl-animate-out-1 {
    -webkit-animation: MenuAnimOut1 0.4s;
    animation: MenuAnimOut1 0.4s;
}

.dl-menuwrapper .dl-menu.dl-animate-out-2 {
    -webkit-animation: MenuAnimOut2 0.3s ease-in-out;
    animation: MenuAnimOut2 0.3s ease-in-out;
}

.dl-menuwrapper .dl-menu.dl-animate-out-3 {
    -webkit-animation: MenuAnimOut3 0.4s ease;
    animation: MenuAnimOut3 0.4s ease;
}

.dl-menuwrapper .dl-menu.dl-animate-out-4 {
    -webkit-animation: MenuAnimOut4 0.4s ease;
    animation: MenuAnimOut4 0.4s ease;
}

.dl-menuwrapper .dl-menu.dl-animate-out-5 {
    -webkit-animation: MenuAnimOut5 0.4s ease;
    animation: MenuAnimOut5 0.4s ease;
}

@-webkit-keyframes MenuAnimOut1 {
    0% {
    }
    50% {
        -webkit-transform: translateZ(-250px) rotateY(30deg);
    }
    75% {
        -webkit-transform: translateZ(-372.5px) rotateY(15deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut2 {
    0% {
    }
    100% {
        -webkit-transform: translateX(-100%);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut3 {
    0% {
    }
    100% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut4 {
    0% {
    }
    100% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut5 {
    0% {
    }
    100% {
        -webkit-transform: translateY(40%);
        opacity: 0;
    }
}

@keyframes MenuAnimOut1 {
    0% {
    }
    50% {
        -webkit-transform: translateZ(-250px) rotateY(30deg);
        transform: translateZ(-250px) rotateY(30deg);
    }
    75% {
        -webkit-transform: translateZ(-372.5px) rotateY(15deg);
        transform: translateZ(-372.5px) rotateY(15deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateZ(-500px) rotateY(0deg);
        transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
}

@keyframes MenuAnimOut2 {
    0% {
    }
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes MenuAnimOut3 {
    0% {
    }
    100% {
        -webkit-transform: translateZ(300px);
        transform: translateZ(300px);
        opacity: 0;
    }
}

@keyframes MenuAnimOut4 {
    0% {
    }
    100% {
        -webkit-transform: translateZ(-300px);
        transform: translateZ(-300px);
        opacity: 0;
    }
}

@keyframes MenuAnimOut5 {
    0% {
    }
    100% {
        -webkit-transform: translateY(40%);
        transform: translateY(40%);
        opacity: 0;
    }
}

.dl-menuwrapper .dl-menu.dl-animate-in-1 {
    -webkit-animation: MenuAnimIn1 0.3s;
    animation: MenuAnimIn1 0.3s;
}

.dl-menuwrapper .dl-menu.dl-animate-in-2 {
    -webkit-animation: MenuAnimIn2 0.3s ease-in-out;
    animation: MenuAnimIn2 0.3s ease-in-out;
}

.dl-menuwrapper .dl-menu.dl-animate-in-3 {
    -webkit-animation: MenuAnimIn3 0.4s ease;
    animation: MenuAnimIn3 0.4s ease;
}

.dl-menuwrapper .dl-menu.dl-animate-in-4 {
    -webkit-animation: MenuAnimIn4 0.4s ease;
    animation: MenuAnimIn4 0.4s ease;
}

.dl-menuwrapper .dl-menu.dl-animate-in-5 {
    -webkit-animation: MenuAnimIn5 0.4s ease;
    animation: MenuAnimIn5 0.4s ease;
}

@-webkit-keyframes MenuAnimIn1 {
    0% {
        -webkit-transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
    20% {
        -webkit-transform: translateZ(-250px) rotateY(30deg);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: translateZ(0px) rotateY(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn2 {
    0% {
        -webkit-transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn3 {
    0% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn4 {
    0% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn5 {
    0% {
        -webkit-transform: translateY(40%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

@keyframes MenuAnimIn1 {
    0% {
        -webkit-transform: translateZ(-500px) rotateY(0deg);
        transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
    20% {
        -webkit-transform: translateZ(-250px) rotateY(30deg);
        transform: translateZ(-250px) rotateY(30deg);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: translateZ(0px) rotateY(0deg);
        transform: translateZ(0px) rotateY(0deg);
        opacity: 1;
    }
}

@keyframes MenuAnimIn2 {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes MenuAnimIn3 {
    0% {
        -webkit-transform: translateZ(300px);
        transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        transform: translateZ(0px);
        opacity: 1;
    }
}

@keyframes MenuAnimIn4 {
    0% {
        -webkit-transform: translateZ(-300px);
        transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        transform: translateZ(0px);
        opacity: 1;
    }
}

@keyframes MenuAnimIn5 {
    0% {
        -webkit-transform: translateY(40%);
        transform: translateY(40%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-1 {
    -webkit-animation: SubMenuAnimIn1 0.4s ease;
    animation: SubMenuAnimIn1 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
    -webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
    animation: SubMenuAnimIn2 0.3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-3 {
    -webkit-animation: SubMenuAnimIn3 0.4s ease;
    animation: SubMenuAnimIn3 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-4 {
    -webkit-animation: SubMenuAnimIn4 0.4s ease;
    animation: SubMenuAnimIn4 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-5 {
    -webkit-animation: SubMenuAnimIn5 0.4s ease;
    animation: SubMenuAnimIn5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimIn1 {
    0% {
        -webkit-transform: translateX(50%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn2 {
    0% {
        -webkit-transform: translateX(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn3 {
    0% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn4 {
    0% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn5 {
    0% {
        -webkit-transform: translateZ(-200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn1 {
    0% {
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn2 {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn3 {
    0% {
        -webkit-transform: translateZ(-300px);
        transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        transform: translateZ(0px);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn4 {
    0% {
        -webkit-transform: translateZ(300px);
        transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        transform: translateZ(0px);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn5 {
    0% {
        -webkit-transform: translateZ(-200px);
        transform: translateZ(-200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
    -webkit-animation: SubMenuAnimOut1 0.4s ease;
    animation: SubMenuAnimOut1 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
    -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
    animation: SubMenuAnimOut2 0.3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-3 {
    -webkit-animation: SubMenuAnimOut3 0.4s ease;
    animation: SubMenuAnimOut3 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-4 {
    -webkit-animation: SubMenuAnimOut4 0.4s ease;
    animation: SubMenuAnimOut4 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-5 {
    -webkit-animation: SubMenuAnimOut5 0.4s ease;
    animation: SubMenuAnimOut5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimOut1 {
    0% {
        -webkit-transform: translateX(0%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(50%);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut2 {
    0% {
        -webkit-transform: translateX(0%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(100%);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut3 {
    0% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut4 {
    0% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut5 {
    0% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-200px);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut1 {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut2 {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut3 {
    0% {
        -webkit-transform: translateZ(0px);
        transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-300px);
        transform: translateZ(-300px);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut4 {
    0% {
        -webkit-transform: translateZ(0px);
        transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(300px);
        transform: translateZ(300px);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut5 {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-200px);
        transform: translateZ(-200px);
        opacity: 0;
    }
}

/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
    position: relative;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
    display: block;
}

.no-js .dl-menuwrapper li.dl-back {
    display: none;
}

.no-js .dl-menuwrapper li > a:not(:only-child) {
    background: rgba(0, 0, 0, 0.1);
}

.no-js .dl-menuwrapper li > a:not(:only-child):after {
    content: '';
}

/* Colors for demos */
/* Demo 1 */
.demo-1 .dl-menuwrapper button {
    background: #c62860;
}

.demo-1 .dl-menuwrapper button:hover,
.demo-1 .dl-menuwrapper button.dl-active,
.demo-1 .dl-menuwrapper ul {
    background: #9e1847;
}

/* Demo 2 */
.demo-2 .dl-menuwrapper button {
    background: #e86814;
}

.demo-2 .dl-menuwrapper button:hover,
.demo-2 .dl-menuwrapper button.dl-active,
.demo-2 .dl-menuwrapper ul {
    background: #D35400;
}

/* Demo 3 */
.demo-3 .dl-menuwrapper button {
    background: #08cbc4;
}

.demo-3 .dl-menuwrapper button:hover,
.demo-3 .dl-menuwrapper button.dl-active,
.demo-3 .dl-menuwrapper ul {
    background: #00b4ae;
}

/* Demo 4 */
.demo-4 .dl-menuwrapper button {
    background: #90b912;
}

.demo-4 .dl-menuwrapper button:hover,
.demo-4 .dl-menuwrapper button.dl-active,
.demo-4 .dl-menuwrapper ul {
    background: #79a002;
}

/* Demo 5 */
.demo-5 .dl-menuwrapper button {
    background: #744783;
}

.demo-5 .dl-menuwrapper button:hover,
.demo-5 .dl-menuwrapper button.dl-active,
.demo-5 .dl-menuwrapper ul {
    background: #643771;
}

.mainnav {
    padding: 0;
    z-index: 99;
    border-radius: 0;
    background: transparent;
    border: none;
}

@media (max-width: 1031px) {
    .mainnav {
        padding: 15px;
    }
}

@media (min-width: 1031px) {
    .mainnav .dl-trigger {
        display: none;
    }
}

.xv-menuwrapper {
    float: right;
}

.xv-menuwrapper .dl-trigger {
    display: none;
}

.xv-menuwrapper .dl-menu {
    margin: 0;
    display: flex;
    position: static;
    padding: 0;
    text-align: left;
    float: none;
}

.xv-menuwrapper .dl-menu > li.parent {
    position: relative;
}

.xv-menuwrapper .dl-menu > li.parent > a:after {
    content: "";
    position: absolute;
    color: #86939e;
    font-family: paperIcons;
    right: 0;
}

.xv-menuwrapper .dl-menu > li.parent:hover > a:after {
    content: "";
    position: absolute;
    font-family: paperIcons;
    top: 43%;
    font-size: 36px;
    left: 32px;
    color: rgba(120, 130, 140, 0.13);
}

.xv-menuwrapper .dl-menu > li.active > a {
    color: #03a9f4;
}

.xv-menuwrapper .dl-menu > li > a {
    padding: 0 20px;
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #86939e;
    line-height: 90px;
    position: relative;
    margin-left: 15px;
}

.xv-menuwrapper .dl-menu > li.megamenu.active > a:after {
    font-family: paperIcons;
    content: "f0d8";
    width: 100%;
    text-align: center;
    height: 7px;
    position: absolute;
    left: 0;
    bottom: 4px;
    color: #fff;
    font-size: 20px;
    bottom: 42px;
}

.xv-menuwrapper .dl-menu > li.megamenu.active:after {
    display: none;
}

.xv-menuwrapper .dl-menu > li .btn {
    color: #fff;
    line-height: 20px !important;
    vertical-align: middle;
}

.xv-menuwrapper .dl-menu li.parent {
    position: relative;
}

.xv-menuwrapper .dl-menu li.parent.megamenu {
    position: static;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > .lg-submenu {
    display: flex !important;
    align-content: center !important;
    flex-wrap: wrap !important;
    width: auto;
    text-align: justify;
    left: auto;
    right: 0;
    padding: 15px 20px 0;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > .lg-submenu.lg-submenu-left {
    left: 20%;
    right: auto !important;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > .lg-submenu::after {
    width: 100%;
    display: inline-block;
    content: ".";
    visibility: hidden;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > .lg-submenu > li {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    position: relative;
    padding: 20px;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > .lg-submenu > li:before {
    height: 100%;
    content: "";
    display: block;
    position: absolute;
    border-right: 1px dashed rgba(120, 130, 140, 0.13);
    top: 0;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > .lg-submenu > li:nth-child(2):before {
    border: none;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > .lg-submenu > li > a {
    font-weight: 400;
    display: block;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > .lg-submenu figure {
    overflow: hidden;
    max-width: 330px;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > .lg-submenu figure img {
    width: 100%;
    height: auto;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > ul > li {
    border-top: none;
}

.xv-menuwrapper .dl-menu li.parent.megamenu > ul > li.parent ul li {
    border-top: none;
}

.xv-menuwrapper .dl-menu li.parent.megamenu li {
    list-style: none;
}

.xv-menuwrapper .dl-menu li.parent.megamenu li a {
    line-height: 25px;
    margin-bottom: 15px;
}

.xv-menuwrapper .dl-menu li.parent:last-child > ul {
    left: auto;
    right: 0;
}

.xv-menuwrapper .dl-menu li.parent:hover > ul {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
}

.xv-menuwrapper .dl-menu li.parent > ul {
    background: #fff;
    padding: 0;
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 215px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -o-transform: translateY(10px);
    -ms-transform: translateY(10px);
    border-radius: 5px;
    box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
}

.xv-menuwrapper .dl-menu li.parent > ul > li {
    display: block;
    list-style: none;
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    padding: 8px 10px;
}

.xv-menuwrapper .dl-menu li.parent > ul > li > a {
    display: block;
    line-height: 30px;
}

.xv-menuwrapper .dl-menu li.parent > ul > li.parent ul {
    position: absolute;
    left: 100%;
    top: 0;
}

.xv-menuwrapper .dl-menu li.parent > ul > li.parent:after {
    position: absolute;
    top: 0;
    right: 15px;
    line-height: 50px;
    font-family: paperIcons;
    speak: none;
    -webkit-font-smoothing: antialiased;
    content: "";
    color: #86939e;
}

.xv-menuwrapper .dl-menu li.parent > ul > li.parent:hover > ul {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
}

.xv-menuwrapper .dl-menu li.parent:last-child > ul > li.parent > ul {
    left: auto;
    right: 100%;
}

.xv-menuwrapper .dl-menu a {
    font-size: 12px;
}

.xv-menuwrapper .dl-menu a i {
    padding-right: 15px;
}

.xv-menuwrapper button:after {
    display: none;
}

.xv-menuwrapper .dl-back {
    display: none !important;
}

.dl-menuwrapper {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.dl-menuwrapper .dl-menu {
    box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
    z-index: 9999;
    border-radius: 2px;
}

.dl-menuwrapper .dl-menu .btn {
    border-radius: 0;
    padding: 0;
}

.dl-menuwrapper button {
    background: #03a9f4;
    float: right;
}

.dl-menuwrapper button:hover, .dl-menuwrapper button.dl-active {
    background: #03a9f4;
}

.dl-menuwrapper ul {
    background: #fff;
}

.dl-menuwrapper ul li {
    border-bottom: 1px solid #fafafa;
}

.dl-menuwrapper ul li:last-child {
    border: none;
}

.dl-menuwrapper ul li a {
    color: #86939e;
}

.fixed-navigation.dl-menuwrapper {
    position: fixed;
    right: -80px;
    top: 40px;
    z-index: 9999;
}

.dl-trigger-wrapper {
    position: relative;
}

.lg-submenu:hover:active:focus::before {
    content: "";
    position: absolute;
    font-family: paperIcons;
    top: -11px;
    font-size: 22px;
    left: 32px;
    color: #ddd;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.dropdown-toggle:hover {
    background-color: transparent;
    color: #03a9f4;
}

.mini-nav .paper-nav-toggle, .nav-offcanvas-desktop .paper-nav-toggle {
    display: block;
}

.xv-menuwrapper .dl-menu > li.megamenu .lg-submenu li {
    line-height: 30px;
}

.xv-menuwrapper .dl-menu > li.megamenu .lg-submenu li .icon {
    margin-left: 0;
}

.tags li a, .widget-tags a, .xv-menuwrapper .dl-menu > li.parent > ul, .xv-menuwrapper .dl-menu > li.parent > ul > li.parent > ul, .navbar-default ul.dropdown-menu li a, .widget-cart-items {
    transition: all 0.3s ease-in-out 0s;
}

@media screen and (min-width: 768px) {
    .mini-nav .navbar {
        margin-bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        border: none !important;
        padding: 15px 0;
    }
}

.nav-absolute nav {
    position: absolute;
    right: 0;
    left: 0;
}

.nav-absolute nav.original .xv-menuwrapper .dl-menu > li > a {
    color: #fff;
}

.nav-absolute nav.original .xv-menuwrapper .dl-menu > li.parent > a:after {
    color: #fff;
}

.nav-light nav .xv-menuwrapper .dl-menu > li > a {
    color: #fff;
}

.nav-light nav .xv-menuwrapper .dl-menu > li.parent > a:after {
    color: #fff;
}

.nav-border nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.nav-border-dark nav {
    border-bottom: 1px solid #e1e8ee !important;
}

.nav-slim .xv-menuwrapper .dl-menu > li > a {
    line-height: 60px;
}

.nav-slim .xv-menuwrapper li.parent.megamenu > .lg-submenu.lg-submenu-left {
    left: auto;
}

.nav-sticky.sticky nav {
    position: relative !important;
    transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
}

nav ul {
    padding: 0;
}

nav strong {
    font-weight: 400;
    font-size: 12px;
}

@media (min-width: 1031px) {
    nav li .divider {
        border-bottom: 1px dashed rgba(120, 130, 140, 0.13);
        margin: 5px 0;
    }
}

nav .b-l, nav .b-r {
    border-color: rgba(255, 255, 255, 0.2);
}

nav .user-avatar img {
    width: 40px;
    margin-left: 5px;
}

nav.nav-inverse .xv-menuwrapper .dl-menu > li.parent ul {
    background: #272C33;
}

nav.nav-flat .dl-menu > li > a {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 100;
}

nav.nav-flat .navbar-brand {
    font-size: 2.1rem;
}

nav .badge, .offcanvas .badge {
    border-radius: 1px;
    padding: 4px 7px;
    margin-left: 5px;
    border-radius: 2px;
}

/*
---------------------------------------------------------------------
| Bootstrap Default nav
|--------------------------------------------------------------------
*/
.navbar {
    margin-bottom: 0;
}

.navbar .brand img {
    padding-top: 25px;
}

.navbar li a {
    padding-right: 20px;
    color: #86939e;
}

.navbar li a:hover, .navbar li a:focus {
    opacity: 0.8;
    text-decoration: none;
    outline: medium none;
    color: #03a9f4;
}

.navbar .nav-btn {
    border-radius: 3px;
    padding: 8px 20px !important;
}

.navbar .dropdown .dropdown-item {
    padding: 0.25rem 1.0rem;
    font-size: 12px;
    font-weight: 300;
}

.navbar .icon {
    margin-left: 10px;
}

.masonry-container.masonry-post-margin .masonry-post, .paper-items.masonry-post-margin .masonry-post {
    margin-bottom: 20px;
}

.masonry-container.masonry-post-margin .masonry-post .lightSlider, .masonry-container.masonry-post-margin .masonry-post .lSSlide, .paper-items.masonry-post-margin .masonry-post .lightSlider, .paper-items.masonry-post-margin .masonry-post .lSSlide {
    width: 400px !important;
}

.masonry-container figure, .paper-items figure {
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04), 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 20px;
}

.masonry-container figure.figure, .paper-items figure.figure {
    box-shadow: none;
    margin: 0;
}

.masonry-container figure .img-wrapper, .paper-items figure .img-wrapper {
    position: relative;
    overflow: hidden;
}

.masonry-container figure img, .paper-items figure img {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition: -webkit-transform 400ms;
    transition: transform 400ms;
}

.masonry-container figure:hover img, .paper-items figure:hover img {
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
}

.masonry-container figure:hover .img-overlay, .paper-items figure:hover .img-overlay {
    opacity: 1;
}

.masonry-container figure:hover .img-overlay .buttons a, .paper-items figure:hover .img-overlay .buttons a {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

.masonry-container figure .img-overlay, .paper-items figure .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background: rgba(255, 255, 255, 0.9);
    opacity: 0;
    -webkit-transition: opacity 400ms;
    transition: opacity 400ms;
}

.masonry-container figure .buttons, .paper-items figure .buttons {
    text-align: center;
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.masonry-container figure figcaption, .masonry-container figure .figcaption, .paper-items figure figcaption, .paper-items figure .figcaption {
    padding: 20px 25px;
    margin-top: 0;
    color: #86939e;
}

.masonry-container figure figcaption h5, .masonry-container figure .figcaption h5, .paper-items figure figcaption h5, .paper-items figure .figcaption h5 {
    margin: 0;
}

.masonry-container figure figcaption p, .masonry-container figure .figcaption p, .paper-items figure figcaption p, .paper-items figure .figcaption p {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
}

.masonry-container figure .figcaption, .paper-items figure .figcaption {
    padding: 50px 25px;
}

.masonry-container figure .figcaption p, .paper-items figure .figcaption p {
    margin-bottom: 20px;
}

.masonry-container figure.style2, .paper-items figure.style2 {
    box-shadow: none;
}

.masonry-container figure.style2 figcaption, .paper-items figure.style2 figcaption {
    padding: 20px 0;
}

.masonry-container.thumb-300 .img-wrapper, .paper-items.thumb-300 .img-wrapper {
    height: 300px;
    overflow: hidden;
    padding: 10px;
}

.breadcrumbs {
    background: #fff;
    padding: 12px;
    border-bottom: 1px solid #e1e8ee;
}

.breadcrumbs ol {
    margin: 0;
    padding: 0;
}

.breadcrumbs ol li {
    display: inline-block;
    color: #5e6977;
    font-size: 14px;
}

.breadcrumbs ol li a {
    text-decoration: none;
    color: #03a9f4;
}

.breadcrumbs ol li::after {
    content: " / ";
    padding: 0 10px;
    color: #e1e8ee;
}

.breadcrumbs ol li:last-child::after {
    content: "  ";
}

@media (max-width: 1031px) {
    .breadcrumbs {
        display: none;
    }
}

.comments .comment-list li {
    position: relative;
    margin-bottom: 15px;
    overflow: visible;
    list-style: none;
    background: #fff;
    border: 1px solid #e1e8ee;
    border-radius: 5px;
}

.comments .comment-list li .avatar {
    border-radius: 50%;
    margin-left: -28px;
    margin-top: 15px;
    padding: 5px;
    position: absolute;
    border: 1px solid #eeeeee;
    height: 58px;
}

.comments .comment-list li .description {
    padding: 15px 30px 10px 48px;
}

.comments .comment-list li .description li {
    background: none;
    padding: 0;
    border: none;
    margin: 0;
}

.comments .comment-list li .reply {
    float: right;
}

.comments .comment-list li .comments-meta {
    border-top: 1px solid #eeeeee;
    background: #f5f8fa;
    padding: 5px 20px;
}

.comments .comment-list li .comments-meta li {
    background: none;
    display: inline;
    border: none;
    margin-top: 0;
}

.comments .comment-list li .comments-meta time::before {
    content: "|";
    display: inline-block;
    padding: 0 30px;
}

.comments .comment-list li .comments-meta a {
    font-size: 14px;
    font-weight: 300;
}

.comments .comment-list li ol.comment-list-child {
    padding: 0;
}

.comments .comment-list li ol.comment-list-child .comment {
    margin-top: 0;
    border: none;
}

.comments .comment-list li ol.comment-list-child .comment .description {
    padding: 15px 48px 10px 30px;
}

.comments .comment-list li ol.comment-list-child .comment .avatar {
    right: -28px;
}

.comments .comment-list.no-m li {
    margin-bottom: 0 !important;
}

.page-header-style1 {
    background: #fff;
    text-align: center;
    border-bottom: none;
    padding: 45px 0 60px;
}

.page-header-style1 h1 {
    color: #606676;
    font-size: 24px;
}

.page-header-style1 p {
    margin: 0 auto;
    padding-top: 30px;
    font-size: 16px;
    width: 60%;
}

.has-overlay {
    z-index: 0;
    background-position: center;
    background-size: cover !important;
    position: relative;
}

.has-overlay .container {
    z-index: 1;
}

.has-overlay small {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 300;
}

.has-overlay h1 {
    font-weight: 100;
    font-size: 36px;
    background-size: cover !important;
}

.has-overlay p {
    font-size: 18px;
    font-weight: 100;
    line-height: 36px;
    margin-top: 20px;
}

@media (max-width: 1031px) {
    .has-overlay {
        text-align: center;
    }
    .has-overlay img {
        margin: 0 auto;
        padding-top: 30px;
    }
}

.overlay {
    z-index: -1;
    opacity: .50;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

footer {
    background-color: #fff;
    border-top: 1px solid #eee;
    padding: 35px 0 0;
}

@media (max-width: 420px) {
    footer .brand {
        text-align: center;
    }
}

footer h6 {
    font-weight: 400;
}

footer .brand img {
    padding-top: 50px;
    padding-bottom: 50px;
}

footer ul {
    padding: 0;
}

footer .footer-links {
    padding-top: 5px;
}

footer .footer-links li {
    display: block;
    list-style: none;
}

footer .footer-links li a {
    color: #86939e;
    line-height: 26px;
}

footer .footer-links li a:hover {
    color: #03a9f4;
}

footer .social {
    padding: 20px 0;
}

footer .copyright {
    color: #5e6977;
}

footer li {
    position: relative;
}

footer .customeIconList {
    margin-top: 30px;
}

footer .customeIconList li {
    position: relative;
    padding-left: 15px;
    display: block;
    margin-bottom: 25px;
}

footer ul.customeIconList > li {
    padding-left: 30px;
}

footer .customeIconList li .icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    color: #5fb7ea;
}

footer hr {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
}

footer .copyrights {
    padding: 55px 0 0;
    font-weight: 300;
}

ul.arrow-list {
    padding: 0;
}

ul.arrow-list li {
    padding-left: 20px;
    margin-bottom: 10px;
    position: relative;
}

ul.arrow-list li::before {
    content: "";
    display: block;
    font-family: paperIcons;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
}

/*----------------------- Widgets ----------------------*/
.widget {
    border: 3px solid #f5f8fa;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 5px 20px 20px;
}

.widget a {
    color: #86939e;
}

.widget h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
}

.widget h3::after {
    background: #1bb1f5;
    content: "";
    display: block;
    height: 3px;
    margin-top: 9px;
    width: 60px;
}

.widget .icon {
    float: right;
    padding-right: 10px;
    padding-right: 20px;
}

.widget-support-forum {
    margin-bottom: 24px;
    margin-top: 50px;
}

.widget-support-forum p {
    font-size: 16px;
    font-weight: 400;
    color: #86939e;
    padding-top: 30px;
    padding-bottom: 30px;
}

.widget_categories li {
    list-style: none;
    padding-left: 5px;
    padding-top: 15px;
}

.widget_categories li a {
    padding-left: 15px;
    color: #86939e;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    outline: 0 none;
}

.widget_categories li a:hover {
    color: #03a9f4;
}

.widget_categories li ul {
    padding-left: 30px;
    padding-bottom: 20px;
}

.widget_categories li ul li:before {
    font-family: paperIcons;
    content: "";
}

.widget_categories li:before {
    font-family: paperIcons;
    content: "";
    color: #b5bfc7;
    font-size: 18px;
}

.search-widget {
    border: none;
    position: relative;
    width: 100%;
    padding: 0;
}

.search-widget button {
    background: none;
    border-style: none;
    color: #e1e8ee;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 0;
    width: 35px;
    font-size: 20px;
}

.search-widget button:hover {
    color: #03a9f4;
}

.shop-product {
    padding: 15px 0;
}

.shop-product .product-info {
    padding-top: 16px;
}

.shop-product .product-info > a {
    font-size: 12px;
    padding-top: 25px;
    font-weight: 400;
}

.shop-product figure {
    float: left;
    width: 80px;
}

.shop-product:last-child {
    border: none;
}

.subscribe form, .newsletter form {
    padding: 30px 0;
}

.subscribe form .icon-btn, .newsletter form .icon-btn {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: #86939e;
    font-size: 30px;
    position: absolute;
    right: 25px;
    top: 44px;
}

.subscribe form .subscribe, .newsletter form .subscribe {
    margin-top: 30px;
}

.hero {
    text-align: center;
}

@media (min-width: 1031px) {
    .hero {
        height: 45rem;
        margin-bottom: 12rem;
    }
}

.hero .section-heading h2 {
    color: #86939e;
    padding-bottom: 20px;
}

.hero .section-heading p {
    font-size: 16px;
}

.hero img {
    max-width: 100%;
}

.hero-circles-bg {
    padding: 50px 0;
    border-top: 1px solid #e1e8ee;
    border-bottom: 1px solid #e1e8ee;
    background: #f5f8fa url("http://xvelopers.com/demos/html/paper-knowledgebase-1.0.0/img/icon/icon-circles.png") no-repeat scroll center bottom;
}

.services img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.services h3 {
    color: #43484d;
    font-size: 15px !important;
    text-align: center;
    font-weight: 400;
}

.services p {
    color: #86939e;
    font-size: 16px;
    margin: 0 auto;
    padding-bottom: 15px;
    padding-top: 30px;
    text-align: center;
}

.newsletter {
    background: url("http://xvelopers.com/demos/html/paper-knowledgebase-1.0.0/img/basic/fan.png") no-repeat scroll center bottom;
    background-color: #f5f8fa;
    position: relative;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.testimonials {
    padding: 0 0 90px;
    color: #5e6977;
    margin: 0 auto;
}

.testimonials .testimonial-content {
    font-weight: 100;
    font-size: 20px;
    max-width: 670px;
    margin: 0 auto;
}

.icon-heading {
    background: transparent url("http://xvelopers.com/demos/html/paper-knowledgebase-1.0.0/img/icon/icon-stone1.png") no-repeat scroll center bottom;
    height: 258px;
    margin-bottom: 36px;
    padding-top: 84px;
}

.users-slider {
    margin: 0 auto;
    max-width: 864px;
    position: relative;
}

.users-slider .lSSlideWrapper {
    position: absolute;
    bottom: 0;
}

.users-slider .lSSlideOuter {
    height: 350px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    text-align: center;
}

.users-slider .lSSlideOuter img {
    width: 80px;
    margin: 0 auto;
    margin-top: 35px;
}

.users-slider .lSSlideOuter .active img {
    transition: all 0.3s;
    width: 160px;
}

.clients {
    padding: 100px 0;
}

#home2carousel {
    margin: 20px 0 100px;
}

#home2carousel img {
    border-radius: 5px;
}

.ab-brand img {
    float: right;
    position: absolute;
    right: 65px;
    top: 24px;
    width: 40px;
}

/***************************** 3- Topics ****************************/
.topics {
    padding: 50px 0;
}

.topics header {
    //border-bottom: 1px solid #eee;
    position: relative;
}

.topics header h2 {
    font-size: 38px;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    color: #43484d;
}

.topics header p {
    font-size: 16px;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    color: #5e6977;
}

.topics .topics-btn {
    margin-top: 30px;
}

.topics .topics-btn .btn {
    margin-top: 60px;
    color: #358ed7;
}

.topics .topics-btn span {
    display: inline;
    padding-left: 30px;
}

.topics h3 a {
    font-size: 18px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #43484d;
    text-decoration: none;
}

.media-border {
    border-bottom: 1px solid #eeeeee;
}

.topics-wrapper {
    border-radius: 5px;
    margin-bottom: 40px;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
}

.topics-wrapper h3 {
    padding: 0;
}

.topics-wrapper .topics-list {
    padding: 20px 0;
    flex-grow: 1;
}

.topics-wrapper .topics-list li {
    list-style: none;
    line-height: 35px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.topics-wrapper .topics-list li a {
    padding-left: 25px;
    color: #86939e;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
    outline: 0 none;
    white-space: nowrap;
}

.topics-wrapper .topics-list li a:hover {
    color: #03a9f4;
}

.topics-wrapper .topics-list li:before {
    font-family: paperIcons;
    content: "\e992";
    color: #b5bfc7;
    font-size: 18px;
}

.topics-wrapper .topics-meta {
    padding: 7px 0;
    margin: 0;
    border-top: 1px solid #e1e8ee;
}

.topics-wrapper .topics-meta li {
    list-style: none;
    display: inline-block;
    color: #5e6977;
    font-size: 14px;
    font-weight: 300;
    padding-right: 10px;
}

.topics-wrapper .icon {
    color: #4ea9e0;
    font-weight: bold;
    margin-right: 15px;
}

.topics-wrapper.border-style {
    border: 3px solid #e1e8ee;
}

.topics-wrapper.border-style h3 {
    padding: 20px 40px 0;
}

.topics-wrapper.border-style .topics-list {
    padding: 10px 40px;
}

.topics-wrapper.border-style .topics-list li {
    position: relative;
    padding-left: 5px;
}

.topics-wrapper.border-style .topics-meta {
    background: #f5f8fa;
    padding: 7px 10px;
    text-align: center;
    border-top: 1px solid #e1e8ee;
}

/* = Tags
-------------------------------------------------------------- */
.tags-list {
    padding: 10px 40px;
}

.tags-list li {
    display: inline-block;

}

.tags-list a {
    font-size: to-rem(12px);
    font-weight: $font-weight-normal;

    line-height: 1.25;
    display: inline-block;
    padding: .1rem .4rem;
    border-radius: 5rem;
    text-transform: uppercase;
    color: #3085ee;
    background: #e1edfd;

    &:hover {
        text-decoration: underline;
    }
}

/* = Icons
-------------------------------------------------------------- */
.icon-left-arrow {
    background: url("./images/icon/icon-left-arrow.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 51px;
    height: 37px;
}

.icon-right-arrow {
    background: url("./images/icon/icon-right-arrow.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 51px;
    height: 37px;
}

.icon-yes {
    background: url("./images/icon/icon-yes.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}

.icon-not {
    background: url("./images/icon/icon-not.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}

.icon-article {
    background: url("./images/icon/icon-article.png.jpeg");
    background-repeat: no-repeat;
    display: inline-block;
    width: 24px;
    height: 32px;
}

.icon-video {
    background: url("./images/icon/icon-video.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}

.icon-img {
    background: url("./images/icon/icon-photo.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}

.icon-envelope-line {
    background: url("./images/icon/icon-envelope.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 64px;
    height: 64px;
}

.icon-download {
    background: url("./images/icon/icon-download.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}

.icon-rocket {
    background: url("./images/icon/icon-rocket.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 64px;
    height: 64px;
}

/*
 * Templates Specific Styles
 */
.blog .post {
    border: 3px solid #f5f8fa;
    border-radius: 3px;
    margin-bottom: 30px;
    padding: 25px;
    position: relative;
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;

    p {
        flex-grow: 1;
    }
}

.blog .ico {
    position: absolute;
    top: 40px;
    font-size: 40px;
    color: #E1E8EE;
}

.blog h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    padding-bottom: 10px;
    padding-top: 100px;
}

.blog h2 a {
    color: #43484d;
}

.blog .pagination > li > a,
.blog .pagination > li > span {
    color: #03a9f4;
}

/*
 * Components
 */
.service-blocks .service-block {
    margin-bottom: 35px;
}

.service-blocks .service-block .service-icon {
    float: left;
}

.service-blocks .service-block .service-icon i {
    border-radius: 100%;
    color: #86939e;
    height: 70px;
    width: 70px;
    display: flex;
    flex-direction: column;
}

.service-blocks .service-block:hover .service-icon i {
    opacity: 0.5;
}

.service-blocks .service-block .service-icon i::before {
    font-size: 60px;
}

.service-blocks .service-block .service-content {
    overflow: hidden;
}

.service-blocks .service-block .service-content h3 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 400;
}

.service-blocks .service-block .service-content h3 a {
    color: #606676;
}

.service-blocks .service-block .service-content h3 a:hover {
    color: #03a9f4;
}

.service-blocks .service-block .btn {
    margin: 20px 0 10px 0;
}

.service-blocks .service-block.s1 .service-icon {
    float: none;
}

.service-blocks .service-block.s2 {
    padding: 20px;
}

.service-blocks .service-block.s2 .service-icon i::before {
    font-size: 40px;
}

.service-blocks .center {
    text-align: center;
}

.service-blocks .center .service-icon {
    float: none !important;
}

.service-blocks .center .service-icon i {
    margin: 0 auto;
}

.single-service-contents {
    padding: 100px 0 65px;
}

.blog .blog-default article {
    text-align: center;
    margin-bottom: 100px;
}

.blog .blog-default .post-type {
    border: 3px solid #f5f8fa;
    border-radius: 50%;
    color: #a2acb4;
    display: inline-block;
    font-size: 18px;
    height: 50px;
    line-height: 45px;
    width: 50px;
}

.latest-posts {
    margin: 50px 0;
}

.blog-box .post {
    border: 3px solid #f5f8fa;
    border-radius: 3px;
    margin-bottom: 30px;
    padding: 25px;
}

.blog-box .post h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    padding-bottom: 10px;
    padding-top: 100px;
}

.blog-box .post h2 a {
    color: #43484d;
}

.blog-box .post h2 a:hover {
    text-decoration: none;
    outline: 0 none;
    color: #03a9f4;
}

.blog-box .icon-article {
    position: absolute;
    top: 50px;
}

.content-wrapper {
    padding: 40px 0;
}

.post .featured img {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.post .meta {
    margin: 20px 0 0;
    padding-bottom: 10px;
}

.post .meta p {
    padding: 15px 0;
}

.post .meta li {
    display: inline-block;
    font-weight: 300;
    color: #86939e;
}

.post .meta li i {
    padding-right: 5px;
}

.post .meta li span {
    color: #43484d;
}

.post .meta li::after {
    content: "|";
    padding: 0 20px;
}

.post .meta li:last-child::after {
    content: none;
}

.post img {
    max-width: 100%;
    height: auto;
}

.post blockquote {
    background: #082c46 none repeat scroll 0 0;
    border: medium none;
    border-radius: 5px;
    color: #fff;
    font-family: monospace;
    font-size: 12px;
    margin: 30px 0;
    padding: 30px 50px;
    line-height: 1.5;
    text-wrap: none;
}

//.post pre.code {
//    background: #082c46 none repeat scroll 0 0;
//    border: medium none;
//    border-radius: 5px;
//    color: #fff;
//    font-family: monospace;
//    font-size: 12px;
//    margin: 30px 0;
//    padding: 30px 50px;
//    line-height: 1.5;
//    white-space: pre-line;
//}

.feedback {
    border-top: 1px solid #eee;
    margin-top: 50px;
}

.feedback input[type="text"] {
    font-size: 18px;
    border-radius: 5px;
    border: 2px solid #e1e8ee;
    color: #43484d;
    height: 60px;
    width: 100%;
    margin: 20px 0;
    padding: 20px;
}

.feedback .count {
    padding-right: 15px;
    padding-top: 18px;
    vertical-align: middle;
}

.feedback .feedback-btn {
    display: inline-block;
    padding-left: 5px;
    padding-top: 20px;
    vertical-align: middle;
}

.single-masthead {
    padding: 30px;
}

.single .post h1 {
    text-align: center;
}

.single .post h2 {
    position: relative;
    margin: 3rem 0 1.5rem;
    &:after {
        content: "";
        position: absolute;
        width: 60%;
        border-bottom: 1px solid #ccc;
        left: -10px;
        bottom: -10px;
    }
}

.single .post h3 {
    margin-top: 2rem;
}

.single .post em {
    color: #a94442;
}

.single .post .meta {
    text-align: center;
    margin: 20px;
    border-bottom: 1px solid #e1e8ee;
}

.single .post img {
    padding-bottom: 25px;
}

.single .post .excerpt {
    font-size: 20px;
    font-weight: 100;
    padding: 40px 0;
}

.single .post-comments {
    background-color: #f5f8fa;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    padding: 50px 0;
    margin-bottom: 50px;
    text-align: center;
}

.single .post-comments .btn {
    margin-top: 15px;
}

.mini li:after {
    padding: 0 13px !important;
}
