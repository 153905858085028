// Letter spacing
.l-s-1 {
  letter-spacing: .2rem;
}
.l-s-2 {
  letter-spacing: .4rem;
}
.l-s-3 {
  letter-spacing: .6rem;
}
.l-s-4 {
  letter-spacing: .8rem;
}
.l-s-5 {
  letter-spacing: 1rem;
}

// Font size
.s-12 {
  font-size: 12px !important;
}
.s-14 {
  font-size: 14px !important;
}
.s-16 {
  font-size: 16px !important;
}
.s-18 {
  font-size: 18px !important;
}
.s-24 {
  font-size: 24px !important;
}
.s-36 {
  font-size: 36px !important;
}
.s-48 {
  @include media-breakpoint-up(lg) {
    font-size: 48px !important;
  }
}
.s-64 {
  @include media-breakpoint-up(lg) {
    font-size: 64px !important;
  }
}
.s-128 {
  @include media-breakpoint-up(lg) {
    font-size: 128px !important;
  }
}
.s-256 {
  font-size: 256px;
  @include media-breakpoint-down(md) {
    font-size: 128px !important;
  }
}


// Width
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.width-250 {
  width: 250px !important;
}
.width-400 {
  width: 400px !important;
}
.width-900 {
  width: 900px !important;
}

// Height
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.height-50 {
  height: 50px;
}
.height-90 {
  height: 90px;
}
.height-100 {
  height: 100px;
}
.height-full {
  background: #F3F5F8;
  min-height: 100vh;
}

// Image
.img-40 {
  width: 40px;
  height: 40px;
}
.img-80 {
  width: 80px;
}
.img-100 {
  width: 100px;
}
.img-150 {
  width: 150px;
}

// Padding
.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.pt-10 {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

// Overflow
.overflow-hide {
  overflow: hidden;
}
